








































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { pricingModule } from '@/store/modules/modulePricing';
import {
  OfferUnitPriceMap,
  OppPricingAmount,
  TaxesAndFees,
} from '@/api/inventory/InventoryModels';
import { requiredFieldRules } from '@/utils/validation-rules';
import {concatTaxesToPrice, roundAmount, zeroPrice} from '@/utils/helpers';
import { productModule } from '@/store/modules/moduleProduct';
import { optionModule } from '@/store/modules/moduleOption';
import { constants } from '@/utils/constants';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import OfferCreateTaxes from '@/components/offer/create/OfferCreateTaxes.vue';
import faker from 'faker';
import { format } from 'date-fns';
import _ from 'lodash';
import { appModule } from '@/store/modules/moduleApp';
import {
  dollarsToCents,
  centsToDollars,
  handleKeyDownOnlyNumeric,
} from '@/utils/helpers';
import { httpModule } from '@/store/modules/moduleHttp';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import { GRSchedule, TimeSlot, OfferPricing, Schedule, FeesPriceAmount } from '@/models';
import { taxesAndFeesModule } from '@/store/modules/moduleTaxAndFees';

@Component({
  components: {
    RdmCollapsableCard,
    OfferCreateTaxes,
  },
})
export default class OfferCreatePricing extends Vue {
  private showTaxes = false;
  private selectedCurrency = 'USD';
  private startDateMenu = false;
  private endDateMenu = false;
  private dayTimeExpand = [0];
  private requiredField = requiredFieldRules;
  private dateFrom = '';
  private dateUntil = '';
  private allDaysSelected = true;
  private totalRetail = 0;
  private totalNet = 0;
  private offerPricings = [] as any;
  private validation = false;
  private handleKeyDown = handleKeyDownOnlyNumeric;
  private addPricingActive = true;
  private selectedScheduleId = '';
  private priceName = '';
  private selectedTravelerTypesAll = [] as Array<string>;
  private selectedScheduleDays = [] as Array<TimeSlot>;
  private createPricingStep = 1;
  private offerPricing = {} as OppPricingAmount;
  private unitPricing = {} as OfferUnitPriceMap;
  private optionUnitPricing = {} as OfferUnitPriceMap;
  private createScheduleDialog = false;
  private modalIsOpen = false;

  get HasValidUnits() {
    const unitCount = this.offerPricings.length;
    if (unitCount === 0) {
      return false;
    }

    let isValid = true;

    this.offerPricings.forEach((u: any) => {
      if (isValid) {
        const valid = this.isValidUnitPrice(u);
        if (!valid) {
          isValid = false;
        }
      }
    });

    return isValid;
  }

  get Schedules() {
    return [
      scheduleModule.DefaultSchedule,
      ...productModule.ProductSchedules,
      ...optionModule.OptionSchedules,
    ];
  }

  get Loading() {
    return pricingModule.Loading;
  }

  get Schedule() {
    return scheduleModule.Schedule;
  }

  get Currency() {
    return taxesAndFeesModule.SelectedCurrency;
  }

  created() {
    scheduleModule.setSchedule({} as Schedule);
  }

  destroyed() {
    this.$root.$off(constants.events.OFFER_CREATE_FORM_4);
  }

  async changeSchedule() {
    const schedule = this.Schedules.find(
      (item) => item.id === this.selectedScheduleId,
    ) as GRSchedule;
    await httpModule.getSchedule(schedule.id);
    const today = format(new Date(), 'yyyy-MM-dd');
    const endDate = this.Schedule.validUntil ? this.Schedule.validUntil : today;
    this.dateFrom =
      this.Schedule.validFrom <= today && today <= endDate
        ? today
        : this.Schedule.validFrom || '';
    this.dateUntil = this.Schedule.validUntil || '';
    this.selectedScheduleDays = this.Schedule.timeSlots;
  }

  changeWeekDay(slots: Array<TimeSlot>, item: any) {
    item.selectedAll = !item.selectedAll;
    slots.map((itm: any) => (itm.editMode = item.selectedAll));
    this.checkIfAllSelected();
  }

  changeTimeSlot(slot: TimeSlot, item: any) {
    slot.editMode = !slot.editMode;
    const check = item?.timeSlots?.filter((item: any) => item.editMode === true);
    item.selectedAll = check.length === item?.timeSlots?.length;
    this.checkIfAllSelected();
  }

  changeAll() {
    if (this.allDaysSelected) {
      this.StructuredSchedule.map((item: any) => {
        item.selectedAll = false;
        item?.timeSlots?.map((slot: TimeSlot) => (slot.editMode = false));
      });
    } else if (!this.allDaysSelected) {
      this.StructuredSchedule.map((item: any) => {
        item.selectedAll = true;
        item?.timeSlots?.map((slot: TimeSlot) => (slot.editMode = true));
      });
    }
    this.checkIfAllSelected();
  }
  checkIfAllSelected() {
    const check = this.StructuredSchedule?.filter((item: any) => {
      return item.selectedAll === true;
    });

    this.allDaysSelected = check.length === this.StructuredSchedule?.length;
  }

  isValidUnitPrice(unit: any) {
    return (
      (unit.net && _.isNumber(+unit.net) && _.toNumber(unit.net) >= 0) ||
      (unit.original && _.isNumber(+unit.original) && _.toNumber(unit.original) >= 0) ||
      (unit.retail && _.isNumber(+unit.retail) && _.toNumber(unit.retail) >= 0) ||
      unit.net === null ||
      unit.original === null ||
      unit.retail === null
    );
  }

  validate1Step() {
    this.validation = true;
    if (this.$refs?.form) {
      const form: any = this.$refs.form;
      const isValid = form.validate();
      if (isValid) {
        this.nextPricing();
      }
    }
  }

  validate2Step() {
    this.validation = true;
    if (this.$refs?.form) {
      const form: any = this.$refs.unitPriceForm;
      const isValid = form.validate();
      const zeroPrices = this.offerPricings?.filter((item: any) => zeroPrice(item));
      if (isValid && zeroPrices.length === 0) {
        this.addPricing();
      } else {
        this.openModal();
      }
    }
  }

  nextPricing() {
    this.configureStep2Data();
    this.offerPricings = this.selectedTravelerTypesAll.map((unit) => {
      return {
        id: unit,
        retail: null,
        net: null,
        original: null,
        totalRetail: null,
        totalNet: null,
        totalOriginal: null,
        optionPrice: null,
        optionPriceRetail: null,
        optionPriceNet: null,
        optionPriceOriginal: null,
        taxPriceRetail: null,
        taxPriceNet: null,
        taxPriceOriginal: null,
        taxName: constants.TAX_FEE,
      };
    });
    this.createPricingStep = 2;
  }

  onChangeRetail(unit: string): void {
    const optionPrice = this.SelectedOption?.unitPrices?.find((u) => u.unitId === unit);
    const offerPricing = this.offerPricings.find((item: any) => item.id === unit);
    let retailPrice = Number(offerPricing.retail);
    const optionRetail = centsToDollars(optionPrice?.amount.retail) || 0;

    this.offerPricings.map((pricing: any) => {
      if (pricing.id === unit && pricing.optionPriceRetail === null) {
        pricing.optionPriceRetail = centsToDollars(optionPrice?.amount?.retail) || null;
      }
    });

    if (optionPrice && optionPrice?.amount.retail) retailPrice += optionRetail;

    this.SelectedTnF.map((tax: TaxesAndFees) => {
      if (retailPrice !== null) retailPrice += concatTaxesToPrice(tax, retailPrice);
    });
    offerPricing.totalRetail = roundAmount(retailPrice);

    if (optionPrice?.amount.retail) {
      offerPricing.taxPriceRetail = roundAmount(
        retailPrice - (Number(offerPricing.retail) + optionRetail),
      );
    } else {
      offerPricing.taxPriceRetail = roundAmount(
        retailPrice - Number(offerPricing.retail),
      );
    }
  }
  onChangeNet(unit: string) {
    const offerPricing = this.offerPricings.find((item: any) => item.id === unit);
    let netPrice = Number(offerPricing.net);
    const optionPrice = this.SelectedOption?.unitPrices?.find((u) => u.unitId === unit);
    const optionNet = centsToDollars(optionPrice?.amount.net) || 0;

    this.offerPricings.map((pricing: any) => {
      if (pricing.id === unit && pricing.optionPriceNet === null) {
        pricing.optionPriceNet = centsToDollars(optionPrice?.amount?.net) || null;
      }
    });

    if (optionPrice && optionPrice.amount.net) netPrice += optionNet;

    this.SelectedTnF.map((tax: TaxesAndFees) => {
      if (netPrice !== null) netPrice += concatTaxesToPrice(tax, netPrice);
    });
    offerPricing.totalNet = roundAmount(netPrice);

    if (optionPrice?.amount.net) {
      offerPricing.taxPriceNet = roundAmount(
        netPrice - (Number(offerPricing.net) + optionNet),
      );
    } else {
      offerPricing.taxPriceNet = roundAmount(netPrice - Number(offerPricing.net));
    }
  }

  onChangeOriginal(unit: string) {
    const optionPrice = this.SelectedOption?.unitPrices?.find((u) => u.unitId === unit);
    const offerPricing = this.offerPricings.find((item: any) => item.id === unit);
    let originalPrice = Number(offerPricing.original);
    const optionOriginal = centsToDollars(optionPrice?.amount.original) || 0;

    this.offerPricings.map((pricing: any) => {
      if (pricing.id === unit && pricing.optionPriceOriginal === null) {
        pricing.optionPriceOriginal =
          centsToDollars(optionPrice?.amount?.original) || null;
      }
    });

    if (optionPrice && optionPrice.amount.original) originalPrice += optionOriginal;

    this.SelectedTnF.map((tax: TaxesAndFees) => {
      if (originalPrice !== null) originalPrice += concatTaxesToPrice(tax, originalPrice);
    });
    offerPricing.totalOriginal = roundAmount(originalPrice);

    if (optionPrice?.amount.original) {
      offerPricing.taxPriceOriginal = roundAmount(
        originalPrice - (Number(offerPricing.original) + optionOriginal),
      );
    } else {
      offerPricing.taxPriceOriginal = roundAmount(
        originalPrice - Number(offerPricing.original),
      );
    }
  }

  async addPricing() {
    const endDt = this.dateUntil;
    const pricing = {
      id: faker.datatype.uuid(),
      name: this.priceName,
      offerId: this.SelectedOffer.id,
      scheduleId: this.selectedScheduleId,
      timeSlots: this.selectedScheduleDays?.filter((item) => item.editMode === true),
      unitPrices: this.offerPricings?.map((unit: any) => {
        const includedFees = [] as Array<FeesPriceAmount>;
        const fee = {} as FeesPriceAmount;
        const price = {
          unitId: unit.id,
          amount: {
            currency: this.Currency,
          } as any,
        };
        if (typeof unit.net === 'string' && unit.net.length) {
          price.amount.net = dollarsToCents(unit.totalNet);
          if (this.SelectedTnF.length) {
            fee.net = dollarsToCents(unit.taxPriceNet);
          }
        }
        if (typeof unit.retail === 'string' && unit.retail.length) {
          price.amount.retail = dollarsToCents(unit.totalRetail);
          if (this.SelectedTnF.length) {
            fee.retail = dollarsToCents(unit.taxPriceRetail);
          }
        }
        if (typeof unit.original === 'string' && unit.original.length) {
          price.amount.original = dollarsToCents(unit.totalOriginal);
          if (this.SelectedTnF.length) {
            fee.original = dollarsToCents(unit.taxPriceOriginal);
          }
        }
        if (Object.keys(fee).length) {
          fee.name = unit.taxName;
          includedFees.push(fee);
        }
        price.amount.includedFees = includedFees;
        return price;
      }),
      validFrom: this.dateFrom,
      validUntil: _.isEmpty(endDt) ? null : endDt,
      fees: this.SelectedTnF.map((fee: TaxesAndFees, i: number) => {
        return {
          taxFeeId: fee.id,
          applicationOrder: i,
        };
      }),
    } as OfferPricing;
    try {
      await httpModule.updateOfferPricing(pricing);
    } catch (e) {
      // TODO handle error
    } finally {
      await this.$router.push({
        name: constants.routes.OFFER_EDIT,
        params: { id: this.SelectedOffer.id },
      });
    }
  }

  openModal() {
    this.modalIsOpen = true;
  }

  closeModal() {
    this.modalIsOpen = false;
  }

  resetState() {
    this.priceName = '';
    this.dateFrom = '';
    this.dateUntil = '';
    this.selectedTravelerTypesAll = [] as Array<string>;
    this.selectedScheduleDays = [] as Array<TimeSlot>;
    this.unitPricing = {} as OfferUnitPriceMap;
    this.optionUnitPricing = {} as OfferUnitPriceMap;
    this.createPricingStep = 1;
    this.addPricingActive = false;
  }

  configureStep2Data() {
    if (this.selectedTravelerTypesAll && Array.isArray(this.selectedTravelerTypesAll)) {
      const productId = this.SelectedOfferProductId;
      const optionId = this.SelectedOfferOptionId;
      this.selectedTravelerTypesAll.forEach((t) => {
        this.unitPricing[t] = {
          unitId: t,
          productId: productId,
          amount: {
            net: undefined,
            original: undefined,
            retail: undefined,
          } as OppPricingAmount,
        };
        this.optionUnitPricing[t] = {
          unitId: t,
          productId: productId,
          optionId: optionId && optionId.length > 0 ? optionId : undefined,
          amount: {
            net: undefined,
            original: undefined,
            retail: undefined,
          } as OppPricingAmount,
        };
      });
    }
  }

  getTravelerTypeName(id: string): string {
    const traveler = this.TravelerTypes.find((t) => t.id === id);
    if (traveler && traveler.internalName) {
      return traveler.internalName;
    }
    return '';
  }

  toggleAllTravelerTypes() {
    this.$nextTick(() => {
      if (this.selectedTravelerTypesAll.length === this.TravelerTypes.length) {
        this.selectedTravelerTypesAll = [];
      } else {
        const typeIds = [] as Array<string>;
        this.TravelerTypes.forEach((t) => typeIds.push(t.id || ''));
        this.selectedTravelerTypesAll = typeIds.slice();
      }
    });
  }

  goToViewProduct() {
    this.createScheduleDialog = false;
    if (this.SelectedOfferProductId && this.SelectedOfferProductId.length > 0) {
      this.$router
        .push({
          name: constants.routes.PRODUCT_DETAILS,
          params: { id: this.SelectedOfferProductId },
          hash: '#schedule',
        })
        .catch();
    }
  }

  get StructuredSchedule() {
    if (!this.Schedule.id || !this.Schedule.timeSlots) return [];
    const days = [] as Array<string>;
    let timeSlots = [] as any;
    const structured = [] as any;
    this.Schedule.timeSlots.map((item) => {
      if (!days.includes(item.weekday)) {
        days.push(item.weekday);
      }
    });
    days.map((day) => {
      this.Schedule.timeSlots.map((item) => {
        if (item.weekday === day) {
          item.editMode = true;
          timeSlots.push(item);
        }
      });
      structured.push({
        weekday: day,
        selectedAll: true,
        timeSlots: timeSlots,
      });
      timeSlots = [];
    });
    return structured;
  }

  get Form4Valid() {
    return pricingModule.CreateForm4Valid;
  }

  set Form4Valid(valid: boolean) {
    pricingModule.setCreateForm4Valid(valid);
  }

  get Form5Valid() {
    return pricingModule.CreateForm5Valid;
  }

  set Form5Valid(valid: boolean) {
    pricingModule.setCreateForm5Valid(valid);
  }

  get SelectedOfferProductId() {
    return pricingModule.SelectedOfferProductId;
  }

  get SelectedOfferOptionId() {
    return pricingModule.SelectedOfferOptionId;
  }

  get TravelerTypes() {
    return pricingModule.TravelerTypes;
  }

  get TravelerTypeSelectIcon() {
    if (this.selectedTravelerTypesAll.length === this.TravelerTypes.length) {
      return 'mdi-close-box';
    }
    if (
      this.selectedTravelerTypesAll.length > 0 &&
      this.selectedTravelerTypesAll.length < this.TravelerTypes.length
    ) {
      return 'mdi-minus-box';
    }
    return 'mdi-checkbox-blank-outline';
  }

  get SelectedProduct() {
    return pricingModule.SelectedOfferProduct;
  }

  get SelectedOption() {
    return pricingModule.SelectedOfferOption;
  }

  get OfferUnitPrices() {
    return pricingModule.UnitPricingList;
  }

  get SelectedOffer() {
    return pricingModule.Offer;
  }

  get SelectedTnF(): Array<TaxesAndFees> {
    return pricingModule.SelectedTaxesAndFees;
  }

  get Currencies() {
    return appModule.Codes?.currency || [];
  }

  get DateFromFormatted() {
    if (this.dateFrom && this.dateFrom.length > 0 && _.isDate(new Date(this.dateFrom))) {
      const date = new Date(this.dateFrom);
      return format(date, 'MM/dd/yyyy');
    }
    return '';
  }

  get DateUntilFormatted() {
    if (
      this.dateUntil &&
      this.dateUntil.length > 0 &&
      _.isDate(new Date(this.dateUntil))
    ) {
      const date = new Date(this.dateUntil);
      return format(date, 'MM/dd/yyyy');
    }
    return '';
  }

  private formattedCurrencies() {
    return this.Currencies.map((item: any) => {
      return { ...item, itemText: `${item.description} (${item.value})` };
    });
  }
  private updateSelectedCurrency() {
    taxesAndFeesModule.updateSelectedCurrency(this.selectedCurrency);
  }
}
