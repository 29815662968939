export const config = {
  get ApiUrl() {
    return process.env.VUE_APP_API_URL;
  },
  get BackplaneApiUrl() {
    if (!process.env.VUE_APP_BACKPLANE_API_URL) return '/api/backplane';
    return process.env.VUE_APP_BACKPLANE_API_URL;
  },
  get ApiTimeout() {
    if (!process.env.VUE_APP_API_TIMEOUT) return '30000';
    return process.env.VUE_APP_API_TIMEOUT;
  },
  get OppApiUrl() {
    if (!process.env.VUE_APP_OPP_API_URL) return '/api/inventory';
    return process.env.VUE_APP_OPP_API_URL;
  },
  get ChannelApiUrl() {
    if (!process.env.VUE_APP_CHANNEL_API_URL) return '/api/channel';
    return process.env.VUE_APP_CHANNEL_API_URL;
  },
  get ReportingApiUrl() {
    if (!process.env.VUE_APP_REPORTS_API_URL) return '/api/reports';
    return process.env.VUE_APP_REPORTS_API_URL;
  },

  get CookiesExpirationTime() {
    if (!process.env.VUE_APP_COOKIES_EXPIRATION_TIME) return '24h';
    return process.env.VUE_APP_COOKIES_EXPIRATION_TIME;
  },
  get ReportsLimit() {
    if (!process.env.VUE_APP_REPORTS_LIMIT) return 200;
    return Number(process.env.VUE_APP_REPORTS_LIMIT);
  },
  get Env() {
    return process.env.VUE_APP_ENV;
  },
  get IsProductionEnv() {
    return this.Env === 'production';
  },
  get IsLocalEnv() {
    return this.Env === 'local';
  },
  get Version() {
    return process.env.VUE_APP_VERSION;
  },
  get GoogleAnalyticsTagId() {
    if (!process.env.VUE_APP_GOOGLE_TAG_ID) return 'G-TBWZGPFLPC';
    return process.env.VUE_APP_GOOGLE_TAG_ID;
  },

  Validate() {
    if (!this.ApiUrl) {
      throw new Error('No VUE_APP_API_URL env variable set');
    }
    if (!this.Env) {
      throw new Error('No VUE_APP_ENV env variable set');
    }
    if (!this.Version) {
      throw new Error('No VUE_APP_VERSION env variable set');
    }
  },
};
