































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import { taxesAndFeesModule } from '@/store/modules/moduleTaxAndFees';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import { Fee, UnitPriceAmount, UnitPrices } from '@/models';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { httpModule } from '@/store/modules/moduleHttp';
import { offerModule } from '@/store/modules/moduleOffer';
import { TaxesAndFees } from '@/api/inventory/InventoryModels';
import { arrayMove, centsToDollars, dollarsToCents } from '@/utils/helpers';
@Component({
  components: {
    RdmCollapsableCard,
    RdmCollapsableControlCard,
  },
})
export default class OfferEditTaxes extends Vue {
  private centsToDollars = centsToDollars;
  private selectedTnF = [] as Array<TaxesAndFees>;
  private taxesAndFees = [] as Array<TaxesAndFees>;
  private taxesOpen = true;
  private modalOpen = false;
  private taxAndFeesHeaders: Array<Record<string, any>> = [
    { text: 'Name', value: 'name', align: 'left', sortable: false },
    { text: 'Type', value: 'chargeType', align: 'left', sortable: false },
    { text: 'Rate', value: 'rateType', align: 'left', sortable: false },
    { text: 'Calculation', value: 'calcText', align: 'left', sortable: false },
    {
      text: 'Effective Dates',
      value: 'validFrom',
      align: 'left',
      sortable: false,
    },
    { text: '', value: 'view', align: 'right', sortable: false },
  ];

  get Loading() {
    return offerModule.Loading;
  }

  get IndependentOnlyApplied() {
    const independentApplied = this.AppliedTnF.filter((item) => {
      return item.ruleType === 'INDEPENDENT';
    });
    return independentApplied.length === this.AppliedTnF.length;
  }

  get AppliedTnF(): Array<TaxesAndFees> {
    return offerModule.SelectedTaxesAndFees;
  }

  set AppliedTnF(t: Array<TaxesAndFees>) {
    offerModule.setSelectedTaxesAndFees(t);
  }

  get Taxes() {
    const taxes = [] as Array<Fee>;
    taxesAndFeesModule.TaxesAndFees.map((tax) => {
      if (this.OfferPricing.fees) {
        this.OfferPricing.fees.map((item) => {
          if (tax.id === item.taxFeeId)
            taxes.push({
              ...tax,
              applicationOrder: item.applicationOrder,
              calcText: `${tax.includedInPricing ? 'Included in Pricing,' : ''} ${
                tax.ruleType
              }, ${tax.applicationLevel}`,
            });
        });
      }
    });
    return taxes.sort((a, b) => a.applicationOrder - b.applicationOrder);
  }

  get TaxesAndFees() {
    return taxesAndFeesModule.TaxesAndFees.map((item: Fee) => {
      return {
        ...item,
        calcText: `${item.includedInPricing ? 'Included in Pricing,' : ''} ${
          item.ruleType
        }, ${item.applicationLevel}`,
      };
    }).filter((fee) => !this.AppliedTnF.find((f) => f.id === fee.id));
  }

  get Offer() {
    return offerModule.Offer;
  }

  get OfferPricing() {
    return offerModule.OfferPricing;
  }

  get TravelerTypes() {
    const inUse = [] as any;
    if (!this.OfferPricing.unitPrices?.length) return inUse;
    travelerTypeModule.TravelerTypes.map((unit: any) => {
      this.OfferPricing.unitPrices.map((traveler) => {
        if (unit.id === traveler.unitId) inUse.push(unit);
      });
    });
    return inUse;
  }

  destroyed() {
    this.AppliedTnF = [];
  }

  private async addTaxesToPricing() {
    const updatedPricing = {
      ...this.OfferPricing,
      fees: this.AppliedTnF.map((fee: TaxesAndFees, i: number) => {
        return {
          taxFeeId: fee.id,
          applicationOrder: i,
        };
      }),
      unitPrices: this.OfferPricing.unitPrices.map((price: UnitPrices) => {
        const amount = {} as UnitPriceAmount;

        if (price.amount.retail) amount.retail = dollarsToCents(price.amount.retail);
        if (price.amount.net) amount.net = dollarsToCents(price.amount.net);
        if (price.amount.original)
          amount.original = dollarsToCents(price.amount.original);
        amount.currency = this.OfferPricing.unitPrices[0].amount.currency;
        return {
          ...price,
          amount: amount,
        };
      }),
    };
    updatedPricing.version = updatedPricing?.version ? updatedPricing.version + 1 : 1;
    await httpModule.updateOfferPricing(updatedPricing);
    await httpModule.getOfferPricing(updatedPricing.id);
    this.closeModal();
  }

  private calculatePriceWithFee(price: number) {
    let p = price;
    this.AppliedTnF.map((tax: TaxesAndFees) => {
      if (!tax.includedInPricing) {
        if (tax.rateType === 'PERCENTAGE') {
          p += (p / 100) * tax.amount;
        }
        if (tax.rateType === 'FLAT') {
          p += tax.amount * 100;
        }
      }
    });
    return p;
  }

  private applyTaxesAndFees() {
    this.taxesAndFees = this.taxesAndFees.filter(
      (x) => !this.selectedTnF.some((y) => x.id === y.id),
    );
    this.AppliedTnF = [...this.selectedTnF, ...this.AppliedTnF];
    this.selectedTnF = [];
  }

  private removeAppliedTax(tax: TaxesAndFees) {
    this.taxesAndFees = [...this.taxesAndFees, tax];
    this.selectedTnF = [];
    this.AppliedTnF = this.AppliedTnF.filter((item) => item.id !== tax.id);
  }

  private toggleTaxes() {
    this.taxesOpen = !this.taxesOpen;
  }

  private async openModal() {
    this.modalOpen = true;
    await httpModule.getTaxesAndFees();
  }

  private closeModal() {
    this.modalOpen = false;
    offerModule.setSelectedTaxesAndFees([]);
  }

  private moveAppliedUp(index: number) {
    this.AppliedTnF = arrayMove(this.AppliedTnF, index, index - 1);
  }

  private moveAppliedDown(index: number) {
    this.AppliedTnF = arrayMove(this.AppliedTnF, index, index + 1);
  }
}
