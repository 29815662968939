











































































































import { Component, Vue } from 'vue-property-decorator';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import { constants } from '@/utils/constants';
import { productModule } from '@/store/modules/moduleProduct';
import { optionModule } from '@/store/modules/moduleOption';
import { httpModule } from '@/store/modules/moduleHttp';
import { appModule } from '@/store/modules/moduleApp';
import { pricingModule } from '@/store/modules/modulePricing';
import OfferCreateDetail from '@/components/offer/create/OfferCreateDetail.vue';
import OfferCreatePolicy from '@/components/offer/create/OfferCreatePolicy.vue';
import OfferCreateTaxes from '@/components/offer/create/OfferCreateTaxes.vue';
import OfferCreatePricing from '@/components/offer/create/OfferCreatePricing.vue';
import { incrementMeta } from '@/utils/helpers';
import faker from 'faker';

@Component({
  components: {
    OfferCreatePricing,
    OfferCreatePolicy,
    RdmCollapsableControlCard,
    RdmCollapsableCard,
    RdmReturnToBtn,
    OfferCreateDetail,
    OfferCreateTaxes,
  },
})
export default class ProductOfferCreate extends Vue {
  private routeToOfferList = constants.routes.OFFER_LIST;
  private createdOfferId = '';

  mounted() {
    pricingModule.resetOfferState();
    httpModule.loadProducts();
    httpModule.loadOptions();
    httpModule.getTravelerTypes();
    httpModule.getTaxesAndFees();
  }

  openStep1(step: boolean) {
    pricingModule.setCreateStep1Open(step);
  }

  openStep2(step: boolean) {
    pricingModule.setCreateStep2Open(step);
  }

  openStep3(step: boolean) {
    pricingModule.setCreateStep3Open(step);
  }

  openStep4(step: boolean) {
    pricingModule.setCreateStep4Open(step);
  }

  configureOffer() {
    this.createdOfferId = faker.datatype.uuid();
    const name = `${pricingModule.SelectedProductName}${
      pricingModule.SelectedOptionName && pricingModule.SelectedOptionName.length > 0
        ? ' + ' + pricingModule.SelectedOptionName
        : ''
    }`;

    let description =
      `${pricingModule.SelectedProductDescription}. ${pricingModule.SelectedOptionDescription}`.trim();
    if (description == '.') description = '';

    return {
      id: this.createdOfferId,
      name: name,
      description,
      meta: incrementMeta(),
      productId: pricingModule.SelectedOfferProductId,
      optionId: pricingModule.SelectedOfferOptionId,
      useProductPolicy: !!(
        this.SelectedOfferPolicy && this.SelectedOfferPolicy.isProduct
      ),
      useOptionPolicy: !!(
        this.SelectedOfferPolicy && !this.SelectedOfferPolicy.isProduct
      ),
    };
  }

  async nextStep() {
    // todo http call to create/update offer
    if (this.CreateStep === 1) {
      pricingModule.configurePolicies();
      pricingModule.chooseSelectedOfferProduct();
      pricingModule.chooseSelectedOfferOption();
    }

    if (this.CreateStep === 2) {
      pricingModule.chooseSelectedOfferProduct();
      pricingModule.chooseSelectedOfferOption();
      await httpModule.updateOffer(this.configureOffer());
      await pricingModule.getTravelerTypesForSelectedProductOrOption();
      this.$root.$emit(constants.events.OFFER_CREATE_FORM_4);
    }

    // if (this.CreateStep === 3) {
    //   // prepare data on form 4
    //   this.$root.$emit(constants.events.OFFER_CREATE_FORM_4);
    // }

    pricingModule.nextCreateStep();
    this.toggleOpen(true);
  }

  skipStep() {
    if (this.CreateStep === 3) {
      // prepare data on form 4
      this.$root.$emit(constants.events.OFFER_CREATE_FORM_4);
    }
    pricingModule.nextCreateStep();
    this.toggleOpen(true);
  }

  toggleOpen(open: boolean) {
    const el1 = this.$refs['section1'] as HTMLElement;
    const el2 = this.$refs['section2'] as HTMLElement;
    const el4 = this.$refs['section4'] as HTMLElement;
    switch (this.CreateStep) {
      case 1:
        pricingModule.setCreateStep1Open(open);
        pricingModule.setCreateStep2Open(false);
        pricingModule.setCreateStep3Open(false);
        pricingModule.setCreateStep4Open(false);
        this.$vuetify.goTo(el1);
        return;
      case 2:
        pricingModule.setCreateStep2Open(open);
        pricingModule.setCreateStep3Open(false);
        pricingModule.setCreateStep4Open(false);
        this.$vuetify.goTo(el2);
        return;
      case 3:
        pricingModule.setCreateStep2Open(false);
        pricingModule.setCreateStep3Open(open);
        pricingModule.setCreateStep4Open(false);
        this.$vuetify.goTo(el4);
        return;
      case 4:
        pricingModule.setCreateStep2Open(false);
        pricingModule.setCreateStep3Open(false);
        pricingModule.setCreateStep4Open(open);
        this.$vuetify.goTo(el4);
        return;
      default:
        pricingModule.setCreateStep1Open(open);
        pricingModule.setCreateStep2Open(false);
        pricingModule.setCreateStep3Open(false);
        pricingModule.setCreateStep4Open(false);
        this.$vuetify.goTo(el1);
        return;
    }
  }

  get SelectedOfferPolicy() {
    return pricingModule.SelectedOfferPolicy;
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get AppliedTnF() {
    return pricingModule.SelectedTaxesAndFees;
  }

  get CreateStep() {
    return pricingModule.CreateStep;
  }

  set CreateStep(step: number) {
    pricingModule.setCreateStep(step);
  }

  get Step1Open() {
    return pricingModule.CreateStep1Open;
  }

  set Step1Open(open: boolean) {
    pricingModule.setCreateStep1Open(open);
  }

  get Step2Open() {
    // return this.step2Open;
    return pricingModule.CreateStep2Open;
  }

  get Step3Open() {
    // return this.step3Open;
    return pricingModule.CreateStep3Open;
  }

  get Step4Open() {
    // return this.step4Open;
    return pricingModule.CreateStep4Open;
  }

  get Form1Valid() {
    return pricingModule.CreateForm1Valid;
  }

  set Form1Valid(valid: boolean) {
    pricingModule.setCreateForm1Valid(valid);
  }

  get Form2Valid() {
    // return this.form2Valid;
    return pricingModule.CreateForm2Valid;
  }

  set Form2Valid(valid: boolean) {
    pricingModule.setCreateForm2Valid(valid);
  }

  get Form3Valid() {
    // return this.form2Valid;
    return pricingModule.CreateForm3Valid;
  }

  set Form3Valid(valid: boolean) {
    pricingModule.setCreateForm3Valid(valid);
  }

  get Form4Valid() {
    // return this.form2Valid;
    return pricingModule.CreateForm4Valid;
  }

  set Form4Valid(valid: boolean) {
    pricingModule.setCreateForm4Valid(valid);
  }

  get Step1Done() {
    return pricingModule.CreateStep1Done;
  }

  get Step2Done() {
    return pricingModule.CreateStep2Done;
  }

  get Step3Done() {
    return pricingModule.CreateStep3Done;
  }

  get Currencies() {
    return appModule.Codes?.currency || [];
  }

  get Times() {
    return [];
  }
  //
  // get TravelerTypes() {
  //   return travelerTypeModule.TravelerTypes;
  // }

  get Products() {
    return productModule.Products.sort((a, b) => {
      const upperA = a.internalName?.toUpperCase() || '';
      const upperB = b.internalName?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get Options() {
    return optionModule.Options.sort((a, b) => {
      const upperA = a.internalName?.toUpperCase() || '';
      const upperB = b.internalName?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get SelectedProductId() {
    return pricingModule.SelectedOfferProductId;
  }

  get SelectedOptionId() {
    return pricingModule.SelectedOfferOptionId;
  }

  // get OfferPricing() {
  //   return [];
  // }

  // get IndependentOnlyApplied() {
  //   const independentApplied = this.appliedTnF.filter((item) => {
  //     return item.ruleType === 'INDEPENDENT';
  //   });
  //   return independentApplied.length === this.appliedTnF.length;
  // }

  // get GroupedSchedules() {
  //   return productModule.GroupedAvailabilitySchedules;
  // }
  //
  // get ScheduleViews() {
  //   return productModule.ScheduleViews.filter(
  //     (scheduleViews) => scheduleViews.productId === this.selectedProduct,
  //   );
  // }
  //
  // get ScheduleDetails() {
  //   return this.ScheduleViews.find((s) => s.id === this.selectedSchedule);
  // }

  // get AvailableTimes() {
  //   const avail = {} as DayTimeSchedule;
  //   if (this.ScheduleDetails) {
  //     this.ScheduleDetails.schedules.forEach((s) => {
  //       if (s.daysOfWeek) {
  //         s.daysOfWeek.forEach((d) => {
  //           if (d === Days.Sunday) {
  //             if (!avail.sunday) {
  //               avail.sunday = [];
  //             }
  //             avail.sunday.push({
  //               startTime: s.startTime || '',
  //               endTime: s.endTime || '',
  //               day: Days.Sunday,
  //             });
  //           } else if (d === Days.Monday) {
  //             if (!avail.monday) {
  //               avail.monday = [];
  //             }
  //             avail.monday.push({
  //               startTime: s.startTime || '',
  //               endTime: s.endTime || '',
  //               day: Days.Monday,
  //             });
  //           } else if (d === Days.Tuesday) {
  //             if (!avail.tuesday) {
  //               avail.tuesday = [];
  //             }
  //             avail.tuesday.push({
  //               startTime: s.startTime || '',
  //               endTime: s.endTime || '',
  //               day: Days.Tuesday,
  //             });
  //           } else if (d === Days.Wednesday) {
  //             if (!avail.wednesday) {
  //               avail.wednesday = [];
  //             }
  //             avail.wednesday.push({
  //               startTime: s.startTime || '',
  //               endTime: s.endTime || '',
  //               day: Days.Wednesday,
  //             });
  //           } else if (d === Days.Thursday) {
  //             if (!avail.thursday) {
  //               avail.thursday = [];
  //             }
  //             avail.thursday.push({
  //               startTime: s.startTime || '',
  //               endTime: s.endTime || '',
  //               day: Days.Thursday,
  //             });
  //           } else if (d === Days.Friday) {
  //             if (!avail.friday) {
  //               avail.friday = [];
  //             }
  //             avail.friday.push({
  //               startTime: s.startTime || '',
  //               endTime: s.endTime || '',
  //               day: Days.Friday,
  //             });
  //           } else if (d === Days.Saturday) {
  //             if (!avail.saturday) {
  //               avail.saturday = [];
  //             }
  //             avail.saturday.push({
  //               startTime: s.startTime || '',
  //               endTime: s.endTime || '',
  //               day: Days.Saturday,
  //             });
  //           }
  //         });
  //       }
  //     });
  //   }
  //   return avail;
  // }

  get SelectedProductPolicyId() {
    return pricingModule.SelectedProductPolicyId;
  }

  set SelectedProductPolicyId(policyId: string) {
    pricingModule.setSelectedProductPolicyId(policyId);
  }

  get SelectedProduct() {
    return pricingModule.SelectedOfferProduct;
  }

  get SelectedOption() {
    return pricingModule.SelectedOfferOption;
  }

  get OfferPrices() {
    return pricingModule.OfferPricings;
  }

  get OfferExists() {
    return pricingModule.HasOfferProductOptionCombo;
  }
}
