







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { requiredFieldRules } from '@/utils/validation-rules';
import _ from 'lodash';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import RdmCalendarMonthly from '@/components/common/time/RdmCalendarMonthly.vue';
import RdmCollapsableControlCard from '@/components/common/card/RdmCollapsableControlCard.vue';
import OfferCreatePricingModal from '@/components/offer/edit/OfferEditPricingModal.vue';
import { getYear } from 'date-fns';
import {
  DisplayDate,
  GRSchedule,
  OfferPriceListItem,
  OfferPricingUI,
  TimeSlot,
  UnitPrices,
  UnitPriceAmount,
} from '@/models';
import OfferCreateTaxes from '@/components/offer/create/OfferCreateTaxes.vue';
import { scheduleModule } from '@/store/modules/moduleSchedule';
import { httpModule } from '@/store/modules/moduleHttp';
import {
  centsToDollars,
  dollarsToCents,
  handleKeyDownOnlyNumeric,
  incrementVersion,
  generateProductPrice,
  generateTaxPrice,
  generateOptionAmountInDollars,
  concatTaxesToPrice,
} from '@/utils/helpers';
import { offerModule } from '@/store/modules/moduleOffer';
import { pricingModule } from '@/store/modules/modulePricing';
import { productModule } from '@/store/modules/moduleProduct';
import { optionModule } from '@/store/modules/moduleOption';
import RdmPublishButton from '@/components/common/button/RdmPublishButton.vue';
import OfferEditTaxes from '@/components/offer/edit/OfferEditTaxes.vue';
import OfferEditSchedules from '@/components/offer/edit/OfferEditSchedules.vue';
import { appModule } from '@/store/modules/moduleApp';
import { TaxesAndFees } from '@/api/inventory/InventoryModels';
import { constants } from '@/utils/constants';
import { numberToCurrency } from '@/filters/filters';
@Component({
  methods: { numberToCurrency },
  components: {
    RdmCollapsableCard,
    RdmCalendarMonthly,
    RdmCollapsableControlCard,
    OfferCreatePricingModal,
    RdmPublishButton,
    OfferEditTaxes,
    OfferEditSchedules,
    OfferCreateTaxes,
  },
})
export default class OfferEditPricing extends Vue {
  private showTaxes = false;
  private selectedTravelerTypesAll = [] as Array<string>;
  private availabilitySchedule = '';
  private baseAvailabilitySchedule = '';
  private offerPricingIdToDelete = '';
  private travelerTypeToRemove = '';
  private offerPricingToEdit = {} as any;
  private unitPrices = [] as Array<OfferPricingUI>;
  private isEditOpen = false;
  private currentDate = new Date();
  private pricingOpen = true;
  private pricingModal = false;
  private pricingOfferRemoveModal = false;
  private removeTravelerTypeModal = false;
  private centsToDollars = centsToDollars;
  private dollarsToCents = dollarsToCents;
  private generateProductPrice = generateProductPrice;
  private generateTaxPrice = generateTaxPrice;
  private generateOptionAmountInDollars = generateOptionAmountInDollars;
  private requiredField = requiredFieldRules;
  private selectedScheduleId = '';
  private dateFrom = '';
  private dateUntil = '';
  private selectedScheduleDays = [] as Array<TimeSlot>;
  private dayTimeExpand = [0];
  private startDateMenu = false;
  private endDateMenu = false;
  private allDaysSelected = false;
  private handleKeyDown = handleKeyDownOnlyNumeric;
  private editPriceID = '';
  private defaultUnitPrice = {
    unitId: '',
    total: {
      net: null,
      original: null,
      retail: null,
    },
    productPrice: {
      net: null,
      original: null,
      retail: null,
    },
    includedOptionPrice: {
      net: null,
      original: null,
      retail: null,
    },
    includedFees: {
      net: null,
      original: null,
      retail: null,
    },
  } as OfferPricingUI;

  get TravelerTypeSelectIcon() {
    if (this.selectedTravelerTypesAll.length === this.TravelerTypes.length) {
      return 'mdi-close-box';
    }
    if (
      this.selectedTravelerTypesAll.length > 0 &&
      this.selectedTravelerTypesAll.length < this.TravelerTypes.length
    ) {
      return 'mdi-minus-box';
    }
    return 'mdi-checkbox-blank-outline';
  }

  get Form4Valid() {
    return pricingModule.CreateForm4Valid;
  }
  set Form4Valid(valid: boolean) {
    pricingModule.setCreateForm4Valid(valid);
  }

  get Option() {
    return offerModule.Option || null;
  }

  get ShowRemoveButton() {
    return (
      this.OfferPricingList &&
      this.OfferPricingList.data &&
      this.OfferPricingList.data.length > 1
    );
  }

  get Loading() {
    return pricingModule.Loading;
  }

  get OfferPricingList() {
    return offerModule.OfferPricings;
  }

  get TravelerTypes() {
    return [...optionModule.TravelerTypes, ...productModule.TravelerTypes];
  }

  get Schedule() {
    return scheduleModule.Schedule;
  }

  get Schedules() {
    return [
      scheduleModule.DefaultSchedule,
      ...productModule.ProductSchedules,
      ...optionModule.OptionSchedules,
    ];
  }

  get HasPrices() {
    return !!this.OfferPricingList.data;
  }

  async mounted() {
    if (this.$route.params.id) {
      await httpModule.getOfferPricingList(this.$route.params.id);
    }
    await httpModule.productSchedules(this.Offer.productId);
    if (this.Offer.optionId) await httpModule.optionSchedules(this.Offer.optionId);
    await pricingModule.loadOfferTravelerTypes();
    if (this.Taxes.length) {
      pricingModule.setSelectedTaxesAndFees(this.Taxes);
      this.showTaxes = !!this.Taxes.length;
    }
    this.$root.$on(constants.events.APPLY_TAXES_AND_FEES, this.onApplyTaxes);
  }

  onApplyTaxes() {
    this.offerPricingToEdit?.unitPrices?.map((unit: OfferPricingUI) => {
      this.onChangeRetail(unit.unitId);
      this.onChangeNet(unit.unitId);
      this.onChangeOriginal(unit.unitId);
    });
  }

  isValidUnitPrice(unit: any) {
    return (
      (unit?.net && _.isNumber(+unit.net) && _.toNumber(unit.net) >= 0) ||
      (unit?.original && _.isNumber(+unit.original) && _.toNumber(unit.original) >= 0) ||
      (unit?.retail && _.isNumber(+unit.retail) && _.toNumber(unit.retail) >= 0)
    );
  }

  onChangeTraveller() {
    const existingTravelerTypes = [] as Array<string>;

    const updatedUnitPrices = this.unitPrices.filter((price: OfferPricingUI) => {
      existingTravelerTypes.push(price.unitId);
      return this.selectedTravelerTypesAll.includes(price.unitId);
    });

    const newTravelerTypes = this.selectedTravelerTypesAll.filter(
      (id: string) => !existingTravelerTypes.includes(id),
    );

    newTravelerTypes.map((id: string) => {
      updatedUnitPrices.push({
        ...this.defaultUnitPrice,
        unitId: id,
      });
    });

    this.offerPricingToEdit = {
      ...this.offerPricingToEdit,
      unitPrices: updatedUnitPrices,
    };
  }

  changeAll() {
    if (this.allDaysSelected) {
      this.StructuredSchedule.map((item: any) => {
        item.selectedAll = false;
        item?.timeSlots?.map((slot: TimeSlot) => (slot.editMode = false));
      });
    } else if (!this.allDaysSelected) {
      this.StructuredSchedule.map((item: any) => {
        item.selectedAll = true;
        item?.timeSlots?.map((slot: TimeSlot) => (slot.editMode = true));
      });
    }
    this.checkIfAllSelected();
  }

  checkIfAllSelected() {
    const check = this.StructuredSchedule?.filter((item: any) => {
      return item.selectedAll === true;
    });

    this.allDaysSelected = check.length === this.StructuredSchedule?.length;
  }

  changeWeekDay(slots: Array<TimeSlot>, item: any) {
    item.selectedAll = !item.selectedAll;
    slots.map((itm: any) => (itm.editMode = item.selectedAll));
    this.checkIfAllSelected();
  }

  changeTimeSlot(slot: TimeSlot, item: any) {
    slot.editMode = !slot.editMode;
    item.selectedAll = !item.timeSlots.find((it: any) => it.editMode === false);
    this.checkIfAllSelected();
  }

  toggleAllTravelerTypes() {
    this.$nextTick(() => {
      if (this.selectedTravelerTypesAll.length === this.TravelerTypes.length) {
        this.selectedTravelerTypesAll = [];
      } else {
        const typeIds = [] as Array<string>;
        this.TravelerTypes.forEach((t: any) => typeIds.push(t.id || ''));
        this.selectedTravelerTypesAll = typeIds.slice();
      }
    });
  }

  toggleEdit(id: string) {
    this.editPriceID = id;
    this.generateObjectToEdit();
    this.selectedTravelerTypesAll = this.OfferPricing.unitPrices.map(
      (price: any) => price.unitId,
    );
    this.selectedScheduleId = this.OfferPricing.scheduleId;
    this.checkIfAllSelected();
    this.dateFrom = this.Schedule.validFrom || '';
    this.dateUntil = this.Schedule.validUntil || '';
    this.isEditOpen = !this.isEditOpen;
  }

  closeEditMode() {
    this.editPriceID = '';
    this.isEditOpen = false;
  }

  generateObjectToEdit() {
    this.offerPricingToEdit = { ...this.OfferPricing };
    this.offerPricingToEdit.unitPrices = this.OfferPricing.unitPrices.map(
      (price: any) => {
        let optionPrice = null;
        if (this.Option && this.Option.unitPrices && _.isArray(this.Option.unitPrices)) {
          optionPrice = this.Option.unitPrices.find(
            (optPrice: any) => optPrice.unitId === price.unitId,
          );
        }
        const offerPrice = {
          unitId: price.unitId,
          total: {
            original: price.amount.original,
            net: price.amount.net,
            retail: price.amount.retail,
          },
          productPrice: {
            original: price.amount.original,
            net: price.amount.net,
            retail: price.amount.retail,
          },
          includedFees: {
            name:
              price.fees &&
              price.fees.length &&
              _.isArray(price.amount?.includedFees) &&
              price.amount?.includedFees.length
                ? price.amount?.includedFees[0].name
                : constants.TAX_FEE,
            retail:
              _.isArray(price.amount?.includedFees) && price.amount?.includedFees.length
                ? price.amount?.includedFees[0].retail
                : null,
            net:
              _.isArray(price.amount?.includedFees) && price.amount?.includedFees.length
                ? price.amount?.includedFees[0].net
                : null,
            original:
              _.isArray(price.amount?.includedFees) && price.amount?.includedFees.length
                ? price.amount?.includedFees[0].original
                : null,
          },
          includedOptionPrice: {
            retail: optionPrice?.amount.retail || null,
            net: optionPrice?.amount.net || null,
            original: optionPrice?.amount.original || null,
          },
        } as any;
        if (offerPrice.includedOptionPrice.retail) {
          offerPrice.productPrice.retail =
            offerPrice.productPrice.retail - offerPrice.includedOptionPrice.retail;
        }
        if (offerPrice.includedOptionPrice.net) {
          offerPrice.productPrice.net =
            offerPrice.productPrice.net - offerPrice.includedOptionPrice.net;
        }
        if (offerPrice.includedOptionPrice.original) {
          offerPrice.productPrice.original =
            offerPrice.productPrice.original - offerPrice.includedOptionPrice.original;
        }
        if (offerPrice.includedFees.retail) {
          offerPrice.productPrice.retail =
            offerPrice.productPrice.retail - offerPrice.includedFees.retail;
        }
        if (offerPrice.includedFees.net) {
          offerPrice.productPrice.net =
            offerPrice.productPrice.net - offerPrice.includedFees.net;
        }
        if (offerPrice.includedFees.original) {
          offerPrice.productPrice.original =
            offerPrice.productPrice.original - offerPrice.includedFees.original;
        }
        offerPrice.productPrice.retail = centsToDollars(offerPrice.productPrice.retail);
        offerPrice.productPrice.net = centsToDollars(offerPrice.productPrice.net);
        offerPrice.productPrice.original = centsToDollars(
          offerPrice.productPrice.original,
        );
        return offerPrice;
      },
    );
    this.unitPrices = this.offerPricingToEdit.unitPrices;
  }

  async savePricingUpdates() {
    const timeSlots = [] as any;
    this.StructuredSchedule.map((day: any) => {
      day.timeSlots.map((slot: any) => {
        if (slot.editMode) {
          timeSlots.push(slot);
        }
      });
    });
    const newPricing = {
      id: this.OfferPricing.id,
      name: this.offerPricingToEdit.name,
      offerId: this.offerPricingToEdit.offerId,
      scheduleId: this.offerPricingToEdit.scheduleId,
      validFrom: this.dateFrom,
      validUntil: this.dateUntil,
      version: incrementVersion(this.OfferPricing.version),
      timeSlots: timeSlots,
      fees: this.OfferPricing.fees,
      unitPrices: this.offerPricingToEdit.unitPrices.map((price: any) => {
        const amount = {} as UnitPriceAmount;
        if (price.total.net !== null) amount.net = price.total.net;
        if (price.total.retail !== null) amount.retail = price.total.retail;
        if (price.total.original !== null) amount.original = price.total.original;
        const p = {
          unitId: price.unitId,
          amount: {
            ...amount,
            currency: this.OfferPricing.unitPrices[0].amount.currency,
            includedFees: [],
          },
        } as UnitPrices;
        if (Object.keys(p).length > 0 && this.OfferPricing.fees.length) {
          p?.amount?.includedFees?.push({
            name: price.includedFees.name,
            net: price.includedFees.net,
            retail: price.includedFees.retail,
            original: price.includedFees.original,
          });
        }
        return p;
      }),
    };
    await httpModule.updateOfferPricing(newPricing);
    await httpModule.getOfferPricingList(this.$route.params.id);
    await httpModule.getUnpublishedChanges(this.$route.params.id);
    this.isEditOpen = false;
    this.editPriceID = '';
  }

  async changeSchedule() {
    const schedule = this.Schedules.find(
      (item) => item.id === this.selectedScheduleId,
    ) as GRSchedule;
    await httpModule.getSchedule(schedule.id);
    this.dateFrom = this.Schedule.validFrom || '';
    this.dateUntil = this.Schedule.validUntil || '';
    this.selectedScheduleDays = this.Schedule.timeSlots;
    this.offerPricingToEdit.scheduleId = this.Schedule.id;
  }

  get StructuredSchedule() {
    if (!this.Schedule.id || !this.Schedule.timeSlots) return [];
    const days = [] as Array<string>;
    let timeSlots = [] as any;
    const structured = [] as any;
    this.Schedule.timeSlots.map((item) => {
      if (!days.includes(item.weekday)) {
        days.push(item.weekday);
      }
    });
    days.map((day) => {
      this.Schedule.timeSlots.map((item) => {
        if (item.weekday === day) {
          item.editMode = _.isArray(this.offerPricingToEdit.timeSlots)
            ? !!this.offerPricingToEdit.timeSlots.find(
                (slot: any) => slot.key === item.key,
              )
            : false;
          timeSlots.push(item);
        }
      });
      structured.push({
        weekday: day,
        selectedAll: timeSlots.length === timeSlots.filter((s: any) => s.editMode).length,
        timeSlots: timeSlots,
      });
      timeSlots = [];
    });
    return structured;
  }

  onChangeRetail(unit: string) {
    const offerPricing = this.offerPricingToEdit.unitPrices.find(
      (item: any) => item.unitId === unit,
    );
    let retailPrice = dollarsToCents(offerPricing.productPrice.retail);

    const optionPrice = offerPricing.includedOptionPrice.retail;

    if (retailPrice !== null) {
      if (optionPrice) {
        retailPrice += optionPrice;
      }
      this.SelectedTnF.map((tax: TaxesAndFees): void => {
        if (retailPrice !== null) retailPrice += concatTaxesToPrice(tax, retailPrice);
      });
    }
    offerPricing.total.retail = retailPrice;
    const productPriceRetail = dollarsToCents(offerPricing.productPrice.retail);
    offerPricing.includedFees.retail =
      retailPrice !== null && productPriceRetail !== null
        ? retailPrice - productPriceRetail
        : retailPrice;

    if (optionPrice) {
      offerPricing.includedFees.retail =
        retailPrice !== null && productPriceRetail !== null
          ? retailPrice - productPriceRetail - optionPrice
          : retailPrice;
    } else {
      offerPricing.includedFees.retail =
        retailPrice !== null && productPriceRetail !== null
          ? retailPrice - productPriceRetail
          : retailPrice;
    }
  }

  onChangeNet(unit: string) {
    const offerPricing = this.offerPricingToEdit.unitPrices.find(
      (item: any) => item.unitId === unit,
    );
    let netPrice = dollarsToCents(offerPricing.productPrice.net);

    const optionPrice = offerPricing.includedOptionPrice.net;

    if (netPrice !== null) {
      if (optionPrice) {
        netPrice += optionPrice;
      }
      this.SelectedTnF.map((tax: any) => {
        if (netPrice !== null) netPrice += concatTaxesToPrice(tax, netPrice);
      });
    }
    offerPricing.total.net = netPrice;
    const productPriceNet = dollarsToCents(offerPricing.productPrice.net);
    offerPricing.includedFees.net =
      netPrice !== null && productPriceNet !== null
        ? netPrice - productPriceNet
        : netPrice;

    if (optionPrice) {
      offerPricing.includedFees.net =
        netPrice !== null && productPriceNet !== null
          ? netPrice - productPriceNet - optionPrice
          : netPrice;
    } else {
      offerPricing.includedFees.net =
        netPrice !== null && productPriceNet !== null
          ? netPrice - productPriceNet
          : netPrice;
    }
  }

  onChangeOriginal(unit: string) {
    const offerPricing = this.offerPricingToEdit.unitPrices.find(
      (item: any) => item.unitId === unit,
    );
    let originalPrice = dollarsToCents(offerPricing.productPrice.original);

    const optionPrice = offerPricing.includedOptionPrice.original;

    if (originalPrice) {
      if (optionPrice) {
        originalPrice += optionPrice;
      }

      this.SelectedTnF.map((tax: any) => {
        if (originalPrice !== null)
          originalPrice += concatTaxesToPrice(tax, originalPrice);
      });
    }

    const productPriceOriginal = dollarsToCents(offerPricing.productPrice.original);
    offerPricing.total.original = originalPrice;
    offerPricing.includedFees.original =
      originalPrice !== null && productPriceOriginal !== null
        ? originalPrice - productPriceOriginal
        : originalPrice;

    if (optionPrice) {
      offerPricing.includedFees.original =
        originalPrice !== null && productPriceOriginal !== null
          ? originalPrice - productPriceOriginal - optionPrice
          : originalPrice;
    } else {
      offerPricing.includedFees.original =
        originalPrice !== null && productPriceOriginal !== null
          ? originalPrice - productPriceOriginal
          : originalPrice;
    }
  }

  removeTravelerType() {
    this.selectedTravelerTypesAll = this.selectedTravelerTypesAll.filter(
      (unitId: string) => unitId !== this.travelerTypeToRemove,
    );
    this.offerPricingToEdit.unitPrices = this.offerPricingToEdit.unitPrices.filter(
      (price: any) => price.unitId !== this.travelerTypeToRemove,
    );
    this.removeTravelerTypeModal = false;
  }

  closeRemoveTravelerType() {
    this.removeTravelerTypeModal = false;
  }
  async deleteOfferPricing(id: string) {
    await httpModule.deleteOfferPricing(id);
    _.delay(async () => {
      await httpModule.getOffer({ offerId: this.$route.params.id });
      offerModule.setProductOfferPricingSetLoading(false);
      this.pricingOfferRemoveModal = false;
    }, 1000);
  }
  openRemovePricingModal(id: string) {
    this.offerPricingIdToDelete = id;
    this.pricingOfferRemoveModal = true;
  }

  openRemoveTravelerTypeModal(id: string) {
    this.travelerTypeToRemove = id;
    this.removeTravelerTypeModal = true;
  }

  openModal() {
    this.pricingModal = true;
    offerModule.setOfferPricings({
      filter: {
        offerId: this.OfferPricing.id,
      },
      data: this.OfferPricingList.data
        ? this.OfferPricingList.data.map((item: OfferPriceListItem) => {
            return {
              ...item,
              open: false,
            };
          })
        : [],
    });
  }
  closeModal() {
    this.pricingModal = false;
  }

  closeRemovePricingModal() {
    this.pricingOfferRemoveModal = false;
  }

  closeRemoveTravelerTypeModal() {
    this.removeTravelerTypeModal = false;
  }

  getTravelerTypeName(id: string): string {
    const traveler = travelerTypeModule.TravelerTypes.find((t) => t.id === id);
    if (traveler && traveler.internalName) {
      return traveler.internalName;
    }
    return '';
  }

  togglePricing() {
    this.pricingOpen = !this.pricingOpen;
  }

  togglePrice(p: any) {
    httpModule
      .getOfferPricing(p.id)
      .then(() => {
        const updatedPriceList = {
          filter: this.OfferPricingList.filter,
          data: this.OfferPricingList.data.map((item: any) => {
            return {
              ...item,
              open: item.id === p.id ? !p.open : false,
            };
          }),
        };
        offerModule.setOfferPricings(updatedPriceList);
        httpModule.getSchedule(offerModule.OfferPricing.scheduleId).then().catch();
      })
      .catch();
  }

  saveSchedule() {
    // todo update name
    const offer = _.cloneDeep(this.Offer);
    offerModule.updateOffer(offer);
    // todo close edit description
    offerModule.toggleEditOfferPricing();
  }

  cancelSchedule() {
    this.availabilitySchedule = this.baseAvailabilitySchedule;
    offerModule.toggleEditOfferPricing();
  }

  selectCalendar(day: DisplayDate) {
    offerModule.selectDisplayDate(day);
  }

  getProductName(productId: string | undefined, optionId: string | undefined): string {
    const product = this.Products.find((p) => {
      return p.id === productId;
    });

    const option = this.Options.find((o) => o.id === optionId);

    return `${product ? product.internalName : ''} ${
      option ? ' + ' + option.internalName : ''
    }`;
  }

  taxToDollars(unit: any, type: string) {
    return generateTaxPrice(unit, type) !== null
      ? centsToDollars(generateTaxPrice(unit, type))
      : generateTaxPrice(unit, type);
  }

  toggleEditCalendarPricing() {
    offerModule.toggleEditOfferCalendar();
  }

  saveCalendarPricing() {
    this.toggleEditCalendarPricing();
  }

  cancelCalendarPricing() {
    this.toggleEditCalendarPricing();
  }

  get Offer() {
    return offerModule.Offer;
  }

  get OfferPricing() {
    return offerModule.OfferPricing;
  }

  get EditOfferPricing() {
    return offerModule.EditOfferPricing;
  }

  get Products() {
    return offerModule.Products;
  }

  get Options() {
    return offerModule.Options;
  }

  get Year() {
    return getYear(this.currentDate);
  }

  get SelectedDisplayDate() {
    return offerModule.SelectedDisplayDate;
  }

  get Currencies() {
    return appModule.Codes?.currency || [];
  }

  get FormattedCurrencies() {
    return this.Currencies.map((item: any) => {
      return { ...item, itemText: `${item.description} (${item.value})` };
    });
  }

  get AppliedCurrencyValue() {
    let curr;
    if (this.OfferPricing.unitPrices?.length) {
      const offerCurr = this.OfferPricing.unitPrices[0].amount.currency;
      curr = this.Currencies.find((item) => item.value === offerCurr);
    }
    return curr?.value || null;
  }

  get AppliedCurrency() {
    let curr;
    if (this.OfferPricing.unitPrices?.length) {
      const offerCurr = this.OfferPricing.unitPrices[0].amount.currency;
      curr = this.Currencies.find((item) => item.value === offerCurr);
    }
    return curr ? `${curr.description} (${curr.value})` : '-';
  }

  get EditCalendarPricing() {
    return offerModule.EditOfferCalendar;
  }

  get ProductOfferPricingLoading() {
    return offerModule.ProductOfferPricingSetLoading;
  }

  get Taxes() {
    const taxes = [] as Array<any>;
    pricingModule.SelectedTaxesAndFees.map((tax) => {
      if (this.OfferPricing.fees) {
        this.OfferPricing.fees.map((item) => {
          if (tax.id === item.taxFeeId)
            taxes.push({
              ...tax,
              applicationOrder: item.applicationOrder,
            });
        });
      }
    });
    return taxes.sort((a, b) => a.applicationOrder - b.applicationOrder);
  }

  get SelectedTnF(): Array<TaxesAndFees> {
    return pricingModule.SelectedTaxesAndFees;
  }
}
